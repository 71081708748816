import AddEditEmissionFactor from '@/components/molecules/Form/AddEditEmissionFactor'
import { withLayout } from '@/components/template/Layout'
import { MESSAGES } from '@/constant/messages'
import { antiWarmingApi, emissionFactorApi, energySavingApi } from '@/ghgApi'
import { AntiWarming, EmissionFactorCreate, EnergySaving } from '@/openapi'
import ReactHookForm from '@/providers/ReactHookForm'
import { TAllFormValues } from '@/react-hook-form/types'
import { schemaName } from '@/react-hook-form/validations'
import { routes } from '@/routes'
import { AppDispatch } from '@/store'
import { emissionStore, getEmissionFactorTables, resetEmissionFactor } from '@/store/slices/emissionSlice'
import { setMessage } from '@/store/slices/messageSlice'
import { AxiosError } from 'axios'
import { navigate } from 'gatsby'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export type EmissionFactorFormTypes = { antiWarmingTypes: AntiWarming[]; energySavingTypes: EnergySaving }

function EmissionFactorForm() {
    const [formTypes, setFormTypes] = useState<EmissionFactorFormTypes | null>(null)
    const dispatch = useDispatch<AppDispatch>()
    const { listEmissionFactorTable, emissionFactor: defaultValues } = useSelector(emissionStore)
    const isEditMode = useMemo(() => {
        return Boolean(defaultValues.id)
    }, [defaultValues.id])
    const params = {
        offset: 0,
        limit: 1000,
    }
    const services = {
        setEmissionFactorTable: async () => {
            try {
                await dispatch(getEmissionFactorTables(params))
            } catch (err) {
                const error = err as AxiosError
                console.log(error.response)
            }
        },
        setFormTypes: async () => {
            try {
                const { data: antiWarmingTypes } = await antiWarmingApi.getAntiWarmingTypes()
                const { data: energySavingTypes } = await energySavingApi.getEnergySavingTypes()
                setFormTypes({ antiWarmingTypes, energySavingTypes })
            } catch (err) {
                const error = err as AxiosError
                console.log(error.response)
            }
        },
    }

    useEffect(() => {
        if (!listEmissionFactorTable.length) {
            services.setEmissionFactorTable()
        }
        if (!formTypes) {
            services.setFormTypes()
        }
    }, [])

    useEffect(
        () => () => {
            if (defaultValues.id) {
                dispatch(resetEmissionFactor())
            }
        },
        [],
    )

    const handleSubmit = async (data: TAllFormValues['addEditEmissionId']) => {
        const { anti_warming, energy_saving, id, value, ...otherKey } = data
        const coefficient = +value
        if (typeof coefficient !== 'number') return
        const isEdit = Boolean(id)
        const reqData: EmissionFactorCreate = {
            ...otherKey,
            value: coefficient,
            emission_factor_table_id: Number(otherKey.emission_factor_table_id),
            anti_warming_id: Number(otherKey.anti_warming_id),
            energy_saving_id: Number(otherKey.energy_saving_id),
        }
        if (anti_warming.includes('None')) {
            reqData.anti_warming_id = undefined
        }
        if (energy_saving.includes('None')) {
            reqData.energy_saving_id = undefined
        }
        try {
            const response = isEdit
                ? await emissionFactorApi.updateEmissionFactor(id, reqData)
                : await emissionFactorApi.createEmissionFactor(reqData)
            const message = isEdit ? MESSAGES.MSG_014 : MESSAGES.MSG_011
            dispatch(setMessage({ message, type: 'success' }))
            navigate(routes.emissionFactorId)
        } catch (err) {
            const error = err as AxiosError
            const message = isEdit ? MESSAGES.MSG_013 : MESSAGES.MSG_010
            dispatch(
                setMessage({
                    message,
                    type: 'error',
                }),
            )
        }
    }
    if (formTypes) {
        return (
            <div className="flex-center py-50">
                <ReactHookForm validateSchema={schemaName} defaultValues={defaultValues}>
                    <AddEditEmissionFactor isEdit={isEditMode} onSubmit={handleSubmit} types={formTypes} />
                </ReactHookForm>
            </div>
        )
    }
    return <></>
}

export default withLayout(EmissionFactorForm)
